/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from 'stimulus-use';

// Controller used to show content on click on another element.
// Content div can be set either by a Stimulus target element, or by an ID

export default class extends Controller {
  static targets = [];

  connect() {
    useClickOutside(this);

    this.isVisible = false;

    this.turboFrameRenderHandler = this.handleTurboFrameRender.bind(this);

    document.documentElement.addEventListener('turbo:frame-render', this.turboFrameRenderHandler);
  }

  handleTurboFrameRender(event) {
    if (['themis-panel-index', 'themis-panel-show'].includes(event.target.id)) {
      this.isVisible = true;
      window.ThemisInstance.updatePlacement();
    }

    if (event.target.id === 'themis-panel-show') {
      this.element.classList.add('themis-panel--show');
    }
  }

  clickOutside(event) {
    if (this.isVisible) {
      // example to close a modal
      event.preventDefault();
      window.ThemisInstance.unRevealThemisableElement();
      this.isVisible = false;

      this.backToIndex();
    }
  }

  backToIndex(e) {
    if (e) e.preventDefault();

    this.element.classList.remove('themis-panel--show');
    window.ThemisInstance.updatePlacement();
  }

  set isVisible(boolean) {
    this._isVisible = boolean;
    if (boolean === false) {
      this.element.classList.add('themis-panel--inactive');
    } else {
      this.element.classList.remove('themis-panel--inactive');
    }
  }

  get isVisible() {
    return this._isVisible;
  }
}
